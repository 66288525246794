import {NetworkService} from "./NetworkService";
import CustomerAddressSearch from "../models/address/CustomerAddressSearch";
import {ICustomerAddressSearch} from "../interfaces/address/ICustomerAddressSearch";
import CustomerAddress from "../models/customer/CustomerAddress";

const controller = "/address";

class AddressService {

    async search(query: string):Promise<ICustomerAddressSearch[]>{

        const {data} = await NetworkService.newConnection().get<ICustomerAddressSearch[]>(`${controller}/search?query=${query}` );

        return data.map(x => new CustomerAddressSearch(x));

    }

    async getById(id: number):Promise<CustomerAddress>{

        const {data} = await NetworkService.newConnection().get<CustomerAddress>(`${controller}/${id}` );

        return new CustomerAddress(data);

    }
}

const service = new AddressService();
export default service;