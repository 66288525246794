import {Box, Button, CircularProgress, Typography, useTheme} from "@mui/material";
import React from "react";
import {NullableString} from "../../../interfaces/NullableStringType";
import productService from "../../../services/ProductService";

interface IProps {
    storeData: null|{store:string, amount: number, unit:string}
    defaultStoreCode: NullableString
    fullCode: string

}

export const OrderFormItemStoreInfo: React.FC<IProps> = (props) => {
    const theme = useTheme();
    const [data, setData] = React.useState<null | { store: string, amount: number, unit: string }>(props.storeData);

    console.log(props.storeData);
    console.log(data);
    console.log(props.defaultStoreCode);
    const [loading, setLoading] = React.useState(false);
    const isRefresh = props.defaultStoreCode === '20';
    let t = null;
    let showAmount = false;
    switch (props.storeData?.store) {
        case '20': t = 'TÁBOR'; showAmount = true; break;
        case '50': t = 'KONSG.'; break;
        default: t = ''; break;
    }

    const handleRefresh = () => {

        if(props.defaultStoreCode === '20'){
            setLoading(true);
            productService.loadFreshProductOnStockDataFromErp(0,'cz', props.fullCode, '20').then((res) => {
                if(res.data && res.data?.length > 0){
                    setData({unit: res.data[0].kod_MJ, store: '20', amount: res.data[0].mnFyzickyStav})
                }

            }).catch(() => {}).finally(() => setLoading(false));
        }
    }

    if(loading){
        return(
            <Box>
                <CircularProgress size={20} />
            </Box>
        )
    }


    if(data === null){
      return(
        isRefresh ?
            <Box>
                <Button onClick={handleRefresh}>Načti</Button>
            </Box>
        : null
      )
    }

    return(
        <Box onClick={handleRefresh} display="flex" flexDirection="column" border={1} borderRadius={1} sx={{padding: 0.5, margin: 0, borderStyle: 'solid', borderColor: theme.palette.grey[300], backgroundColor: isRefresh ? theme.palette.grey[100] : theme.palette.grey[200], cursor: isRefresh ? 'pointer' : 'auto' }} >
            <Box > <Typography variant='note'  >[{data.store}] {t}</Typography></Box>
            <Box> {showAmount ?
                <Typography variant='note' sx={{backgroundColor: '#f3e0be', padding: 0.3, borderRadius: 1}}>{data.amount} {data.unit}</Typography>

                : <Typography variant='note' >N/A</Typography>} </Box>
        </Box>
    )
}