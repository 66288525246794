import {NetworkService} from "./NetworkService";
import {ICustomerFilter} from "../interfaces/customer/ICustomerFilter";
import Customer from "../models/customer/Customer";
import {ICustomerConstructor} from "../interfaces/customer/ICustomerConstructor";
import {ICustomerAddressConstructor} from "../interfaces/customer/ICustomerAddressConstructor";
import CustomerAddress from "../models/customer/CustomerAddress";

const controller = "/customer";

class CustomerService {

    async syncFromErp(countryCode: string ,from: string, auto: boolean):Promise<any>{
         await NetworkService.newConnection().get(`${controller}/country/${countryCode}/sync?from=${from}&auto=${auto}`);
    }

    async filter(f:ICustomerFilter):Promise<Customer[]>{
        let str = Object.keys(f).map(function(key) {
            if(f[key] !== null)  return key + '=' + f[key];

        }).join('&');

        const {data} = await NetworkService.newConnection().get<ICustomerConstructor[]>(controller + "?" + str);

        return data.map(x => new Customer(x));
    }

    async getById( id: number): Promise<ICustomerConstructor>{
        const {data} = await NetworkService.newConnection().get<ICustomerConstructor>(`${controller}/${id}`)
        return new Customer(data);
    }

    async getAddresses(customerId: number):Promise<ICustomerAddressConstructor[]>{

        const {data} = await NetworkService.newConnection().get<ICustomerAddressConstructor[]>(`${controller}/${customerId}/addresses` );

        return data.map(x => new CustomerAddress(x));

    }
}

const customerService = new CustomerService();
export default customerService;