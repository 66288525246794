import {IOrderItemConstructor} from "../../interfaces/order/IOrderItemConstructor";
import UnitLocale from "../unit/UnitLocale";
import {NullableString} from "../../interfaces/NullableStringType";

export class OrderItem {
    productId: number
    amount: number
    sellPrice: number
    sellPriceVat: number
    sellVat: number
    basicUnitId: number
    name: string;
    fullCode: string;
    sumSellPrice: number
    sumSellPriceVat: number
    unitLocale: UnitLocale
    sumSellPriceFixedDigits: string
    sumSellPriceVatFixedDigits: string
    sellPriceFixedDigits: string
    storeData: null|{store:string, amount: number, unit:string}
    defaultStoreCode: NullableString

    constructor(j:IOrderItemConstructor) {
        this.productId = j.productId;
        this.amount = j.amount;
        this.sellPrice = j.sellPrice;
        this.sellVat = j.sellVat;
        this.basicUnitId = j.basicUnitId;
        this.name = j.name;
        this.fullCode = j.fullCode;
        this.unitLocale = new UnitLocale(j.unitLocale);
        //calculate
        this.sellPriceVat = 0;
        this.sumSellPriceVat = 0;
        this.sumSellPrice = 0;
        this.sumSellPriceFixedDigits = '0';
        this.sumSellPriceVatFixedDigits=  '0';
        this.sellPriceFixedDigits = '0';
        this.storeData = j.storeData ?? null;
        this.defaultStoreCode = j.defaultStoreCode;
        this.recalculate();
    }

    public recalculate(){
        this.sumSellPrice = (this.amount * this.sellPrice);
        this.sellPriceVat = (this.sellPrice * ( (this.sellVat / 100) + 1) );
        this.sellPriceFixedDigits = this.sellPrice.toFixed(2);
        this.sumSellPriceVat = (this.amount * this.sellPriceVat);
        this.sumSellPriceVatFixedDigits = this.sumSellPriceVat.toFixed(2);
        this.sumSellPriceFixedDigits = this.sumSellPrice.toFixed(2);
    }
}