import React from "react";
import {Box, Grid, Link, Paper, Typography, useTheme} from "@mui/material";
import {OrdersDialog} from "../order/OrdersDialog";
import {Order} from "../../models/order/Order";

interface IProps {
    order: Order
}

export const CustomerSummaryBox: React.FC<IProps> = (props: IProps) => {

    const [ordersDialogOpen, setOrdersDialogOpen] = React.useState<boolean>(false);
    const theme = useTheme();

    const c = props.order.customer;
    const ca = props.order.customerAddress;

  if(props.order.customer === null){
      return(
      <Paper elevation={1}  sx={{backgroundColor: theme.palette.grey[100], padding: 1.2}}>
          <Typography variant="note" >Vyberte zákazníka</Typography>
      </Paper>)

  }

  const renderAddress = () => {
      if(props.order.customerAddress === null){
          return(
              <Paper elevation={0}  sx={{backgroundColor: theme.palette.grey[200], padding: 1}}>
                  <Typography variant="note" >Vyberte adresu (pobočku)</Typography>
              </Paper>
          )
      }

      return(
          <Paper elevation={0}  sx={{backgroundColor: theme.palette.grey[200], padding: 1}}>
              <Grid container={true} spacing={0.5}>
                  <Grid item xs={12}><Typography variant="note">Dodací adresa:</Typography></Grid>
                  <Grid item xs={12}>
                      <Typography variant="h4" >{ca?.name ?? "N/A"}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                      <Typography variant="h5" >{ca?.street ?? "N/A"}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                      <Typography variant="h5" >{ca?.city + " " + ca?.postal ?? null}</Typography>
                  </Grid>
              </Grid>
          </Paper>
      )
  }


    return (<Paper elevation={1}  sx={{backgroundColor: theme.palette.grey[100], padding: 1.2}}>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
                <Paper elevation={0}  sx={{backgroundColor: theme.palette.grey[200], padding: 1}}>
                    <Grid container={true} spacing={0.5}>
                        <Grid item xs={12}><Typography variant="note">Odběratel:</Typography></Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" >{c!.name}</Typography>
                        </Grid>
                        <Grid item container xs={12}>
                            <Box mr={1}>
                                <Typography variant="h5" >Kód: {c!.code}</Typography>
                            </Box>
                            <Box>
                                <Typography variant="h5" >IČO: {c!.idNumber}</Typography>

                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Link
                                underline={'hover'}
                                component="button"
                                variant="body2"
                                onClick={() => {
                                    setOrdersDialogOpen(true);
                                }}
                            >
                                Zobrazit objednávky
                            </Link>
                        </Grid>

                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                {renderAddress()}
            </Grid>
        </Grid>



        <OrdersDialog onClose={() => {
            setOrdersDialogOpen(false)
        }} open={ordersDialogOpen}/>

    </Paper>)
}