import React, {useEffect} from "react";
import productService from "../../services/ProductService";
import {ProductAddToOrderInfo} from "../../models/product/ProductAddToOrderInfo";
import {Box, CircularProgress, Typography} from "@mui/material";
import {IErpCommResponse} from "../../interfaces/erp/IErpCommResponse";
import {IProductOnStock} from "../../interfaces/product/IProductOnStock";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

interface IProps{
    p: ProductAddToOrderInfo,
    setStoreData: (val: {store:string, amount: number, unit:string}|null) => void

}

export const ProductOnStockBox:React.FC<IProps> = (props) => {

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [response, setResponse] = React.useState<IErpCommResponse<IProductOnStock>|null>(null);

    useEffect(() => {
       if(loaded) return;

       if(props.p.defaultStoreCode === null || (props.p.defaultStoreCode !== '20' && props.p.defaultStoreCode !== '50')){
           setLoaded(true);
           props.setStoreData(null);
       }

        if(props.p.defaultStoreCode === '50'){
            setLoaded(true);
            props.setStoreData({unit: props.p.unitLocale.code, store: '50', amount: 0});
        }


       if(props.p.defaultStoreCode === '20'){
           productService.loadFreshProductOnStockDataFromErp(0,'cz', props.p.fullCode, '20').then((res) => {
               console.log(res);
               setResponse(res);
               if(res.data && res.data?.length > 0){
                   props.setStoreData({unit: res.data[0].kod_MJ, store: '20', amount: res.data[0].mnFyzickyStav})
               }

               setLoaded(true);
           }).catch(() => {}).finally(() => setLoaded(true));
       }


    },[loaded, props.p.defaultStoreCode])

    if(!loaded){
        return(
            <Box display="flex" flexDirection='column' sx={{backgroundColor: '#f5f5f5', padding: 1, borderRadius: 1, border: '1px solid #e6e6e6'}}>
                <CircularProgress size={20} /> <Typography variant='note'>Načítám informace o množství na skladě...</Typography>
            </Box>
        )
    }

    if(props.p.defaultStoreCode === null){
        return(
            <Box display="flex" flexDirection='column' sx={{backgroundColor: '#f5f5f5', padding: 1, borderRadius: 1, border: '1px solid #e6e6e6'}}>
                <Box display='flex' flexDirection='row'>
                    <Typography variant="note" gutterBottom>Informace o množství na skladě</Typography>
                </Box>
                <Box display='flex' flexDirection='row'>
                    <Typography variant="note" gutterBottom>Položka nemá nastavenou hodnotu výchozího skladu</Typography>
                </Box>
            </Box>
        )
    }

    if(props.p.defaultStoreCode === '50'){
        return(
            <Box display="flex" flexDirection='column' sx={{backgroundColor: '#f5f5f5', padding: 1, borderRadius: 1, border: '1px solid #e6e6e6'}}>
                <Box display='flex' flexDirection='row'>
                    <Typography variant="note" gutterBottom>SKLAD 50 - konsignace</Typography>
                </Box>
                <Box display='flex' flexDirection='row'>
                    <Typography variant="note" gutterBottom>Položka je vedena na skladě 50 - konsignace. Bude dodána přímo ze Steriwund. Informace o skladév dostupnosti je tedy bezpředmětná.</Typography>
                </Box>
            </Box>
        )
    }

    return (
        <Box display="flex" flexDirection='column' sx={{backgroundColor: '#f5f5f5', padding: 1, borderRadius: 1, border: '1px solid #e6e6e6'}}>
            <Box display='flex' flexDirection='row' alignItems='flex-start'>
                <Typography variant="note" >SKLAD 20 TÁBOR</Typography>
            </Box>
            <Box display='flex' flexDirection='row' alignItems='flex-start'>
                {response?.isSuccess ? <CheckCircleIcon fontSize='small' color='success' sx={{marginRight: 0.5}} /> : <ErrorIcon color='error' fontSize='small' sx={{marginRight: 0.5}} />}
                <Typography variant="note" gutterBottom>{response?.message}</Typography>
            </Box>
            <Box>
                {response && response.data && response.data.map((x, i) => (
                    <Box key={`mns+${i}`}>
                        <Box>Skladem: {x.mnFyzickyStav} {x.kod_MJ}</Box>
                        <Box>Skladová cena: {x.hdjCena_Skl}</Box>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}