
import {ICustomerAddressSearch} from "../../interfaces/address/ICustomerAddressSearch";

class CustomerAddressSearch {
    addressId: number;
    addressName: string;
    city: string;
    customerId: number;
    customerName: string;
    postal: string;
    street:string;

    constructor(data: ICustomerAddressSearch) {
        this.addressId = data.addressId;
        this.addressName = data.addressName;
        this.city = data.city;
        this.customerId = data.customerId;
        this.customerName = data.customerName;
        this.postal = data.postal;
        this.street = data.street;
    }


}

export default CustomerAddressSearch;