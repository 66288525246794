import React, {useEffect} from "react";
import {
    Box,
    CircularProgress,
    Grid,
    Paper,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {OrderFormitem} from "./OrderFormitem";
import {IProductAutocomplete} from "../../../interfaces/product/IProductAutocomplete";
import {CustomerSummaryBox} from "../../customer/CustomerSummaryBox";
import ProductAutocompleteSearch from "../../product/ProductAutocompleteSearch";
import {AlertAppContext} from "../../alert/AlertAppContext";
import Divider from "@mui/material/Divider";
import {IUnitLocale} from "../../../interfaces/unit/IUnitLocale";
import unitService from "../../../services/UnitService";
import productService from "../../../services/ProductService";
import addressService from "../../../services/AddressService";
import {ProductAddToOrderInfo} from "../../../models/product/ProductAddToOrderInfo";
import {OrderFormProductAddToOrderInfoBox} from "./OrderFormProductAddToOrderInfoBox";
import {Order} from "../../../models/order/Order";
import {OrderFormAlerts} from "./OrderFormAlerts";
import {OrderItem} from "../../../models/order/OrderItem";
import {OrderFormWarehouseBox} from "./OrderFormWarehouseBox";
import {ErpStoreSelect} from "../../erpStore/ErpStoreSelect";
import {IErpStore} from "../../../interfaces/erpStore/IErpStore";
import {NullableString} from "../../../interfaces/NullableStringType";
import {AddressFulltextSelect} from "../../customer/AddressFulltextSelect";
import CustomerAddressSearch from "../../../models/address/CustomerAddressSearch";
import customerService from "../../../services/CustomerService";

interface IProps{
    loaded: boolean;
    setLoaded: (v:boolean) => void;
    order: Order,
    setOrder: (o:Order) => void;

}

export const OrderForm: React.FC<IProps> = (props) => {

    const {t} = useTranslation();
    const order = props.order;
    const setOrder = (o:Order) => {
        props.setOrder(o);
    }
    const appAlert = React.useContext(AlertAppContext);
    const theme = useTheme();
    const [units, setUnits] = React.useState<IUnitLocale[]>([]); //pro vypis mernych jendotek u kazdeho item order

    const [productAddToOrder, setProductAddToOrder] = React.useState<ProductAddToOrderInfo|null>(null);
    const [productAddToOrderIsLoading, setProductAddToOrderIsLoading] = React.useState(false);

    const handleClearProductAddToOrder = () => {
        setProductAddToOrder(null);
    }

    const handleCustomerOrderNumberChange = (val: NullableString) => {

        let o = order.copy();
        o.customerOrderNumber = val;
        setOrder(o);
    }

    const handleInternalNoteChange = (val: NullableString) => {
        let o = order.copy();
        o.noteInternal = val;
        setOrder(o);
    }

 /*   const handleCustomer = (data: ICustomerConstructor) => {

        if(data) return;

        let o = order.copy();
        o.customer = null;
        o.customerAddress = null;
        o.customer = data;
        setOrder(o);

    }*/

    const handleCustomerAddress = async (data:CustomerAddressSearch|null) => {
        let o = order.copy();
        o.customerAddress = null;

        if(data == null) return;

        let customerAddress = await addressService.getById(data.addressId);
        let customer = await customerService.getById(data.customerId);

        console.log(customerAddress);

        o.customer = customer;
        o.customerAddress = customerAddress;
        setOrder(o);

    }

    const handleProductAutocompleteSearch = async (item: IProductAutocomplete | null) =>
    {

        if (item === null) return;

        setProductAddToOrderIsLoading(true);

        const res = await productService.getAddToOrderInfo(item.id, 'cs', 'cz');
        setProductAddToOrderIsLoading(false);
        if(res === null) {
            //todo: O preklad
            appAlert.showAlert({severity: 'warning', message: 'Produkt nejspíše neexistuje v ERP.'})
            return false;
        }
        res.name = item.name;
        setProductAddToOrder(res);

    }

    const handleAddProductToOrderItems = (p: OrderItem) => {
        console.log(order.items);
        const isExist = order.items.find(x => x.productId === p.productId);
        console.log(isExist);

        if (isExist === undefined ) {
            let o = order.copy();
            o.addItem(p);
            setOrder(o);


        } else {
            appAlert.showAlert({severity: "error", message: t('orders.form.productAlreadyExist')});
            return;
        }


    }

    const handleProductChange = (index: number, p:OrderItem) => {
        let o = order.copy();
        o.changeItem(index, p);

        setOrder(o);

    }

    const onDeleteProduct = (index: number) => {
        let o = order.copy();
        o.removeItem(index);
        setOrder(o);
        appAlert.showAlert({severity: "success", message: t('orders.form.deleteSuccesAlert')});
    }

    const handleSklad = (store:IErpStore|null) => {
        let o = order.copy();
        o.erpStore = store;
        setOrder(o);
    }


    useEffect(() => {
        if(!props.loaded ){
            unitService.get("cs").then( (res)  => {
                setUnits(res);
                props.setLoaded(true);
            })

        }

    }, [props, props.loaded, props.setLoaded]);

    if(!props.loaded){
        return(
            <Paper sx={{padding:1, margin: 0}}>
                <CircularProgress />
            </Paper>
        )
    }



    return (
        <Box p={1}>
            <Grid container spacing={2} padding={1} direction={{xs: 'column', md: 'row'}}>
                <Grid item xs={12} md={4}>
                    <ErpStoreSelect variant="buttonGroup" onClick={(store:IErpStore|null) => {handleSklad(store)}} id={order.erpStore?.id ?? null} />
                </Grid>
                <Grid item xs={12} md={8}>
                   <OrderFormWarehouseBox sklad={order.erpStore} />

                </Grid>

                <Grid  item xs={12} md={4}>
                        <Typography variant="h5" gutterBottom>
                            Vybrat Zákazníka
                        </Typography>
                    <Typography variant={"caption"} gutterBottom>
                        Vyhledává dle názvu, IČO, části adresy zákazníka
                    </Typography>


                        <Grid item xs={12} md={12}>
                            <AddressFulltextSelect size="small" onSelect={(item) => handleCustomerAddress(item)}/>
                        </Grid>

                </Grid>
                <Grid item xs={12} md={8}>
                   <CustomerSummaryBox order={order} />
                </Grid>

                {/* CUSTOMER ORDER INFO  */}

                <Grid item xs={0} sm={0} md={4}>

                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <Paper elevation={1}  sx={{backgroundColor: theme.palette.grey[100], padding: 1.2}}>
                        <Paper elevation={0}  sx={{backgroundColor: theme.palette.grey[200], padding: 1}}>
                            <Grid container={true} spacing={1}>
                                <Grid item xs={12}><Typography variant="note">Číslo objednávky odběratele:</Typography></Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField defaultValue={order.customerOrderNumber} inputProps={{ maxLength: 20 }} fullWidth={true} size="small" variant="standard" onChange={(e) => handleCustomerOrderNumberChange(e.target.value)} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Paper>

                </Grid>

            </Grid>
            <Grid container spacing={2} padding={1} direction={{xs: 'column', md: 'row'}}>
                <Grid  item xs={12} sm={12} md={4}>
                        <Typography variant="h5" gutterBottom>
                           Vybrat produkt
                        </Typography>
                        <ProductAutocompleteSearch  size="small" onChange={(item) => {
                            handleProductAutocompleteSearch(item)
                        }} label={'Začněte psát pro vyhledávání'} resetValOnClick={true}/>
                    <Grid item xs={12} md={12}>
                        <OrderFormProductAddToOrderInfoBox onClear={handleClearProductAddToOrder} units={units} onAddItem={(p) => handleAddProductToOrderItems(p)} isLoading={productAddToOrderIsLoading} p={productAddToOrder} />
                    </Grid>
                </Grid>



                {/* ITEMS  */}
                <Grid item xs={12} sm={12} md={8}>
                    <Paper elevation={1}  sx={{backgroundColor: theme.palette.grey[100], padding: 1.2, minHeight: '500px'}}>
                            <Typography variant="note">Položky:</Typography>
                            <>
                                {order.items.length > 0 && order.items.map(function (e, index) {
                                    return <OrderFormitem key={'orderItem_' + index} index={index} product={e} onDelete={(id) => {onDeleteProduct(id)}} onChange={(i,p)=>handleProductChange(i,p)} />
                                })}
                                <Divider/>
                                <Box display={'flex'} justifyContent={'right'} p={2} fontSize={'larger'}>
                                    <Typography sx={{marginRight: 1}} color={'text.primary'}>
                                        Položek: {order.items.length}
                                    </Typography>
                                    <Typography sx={{marginRight: 1}} color={'text.primary'}>
                                        Celkem: {order.sumSellPriceFixedDigits}
                                    </Typography>
                                    <Typography sx={{marginRight: 1}} color={'text.primary'}>
                                        Celkem s DPH: {order.sumSellPriceVatFixedDigits}
                                    </Typography>
                                </Box>
                            </>
                    </Paper>
                </Grid>
                <Grid  item xs={12} sm={12} md={4}>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Paper elevation={1}  sx={{ padding: 1.2}}>
                        <Typography variant="note" >
                            Poznámka interní
                        </Typography>
                        <TextField
                            placeholder="Interní poznámka"
                            multiline
                            fullWidth={true}
                            onChange={(e) => handleInternalNoteChange(e.target.value)}
                            value={order.noteInternal}
                            inputProps={{ maxLength: 499 }}
                        />
                    </Paper>
                </Grid>
              {/*  <Grid item xs={12} sm={12} md={4}>
                    <Paper elevation={1}  sx={{backgroundColor: theme.palette.grey[100], padding: 1.2}}>
                        <Typography variant="note" >
                            Poznámka k zákazníkovi
                        </Typography>
                        <TextField
                            placeholder="Poznámka k zákazníkovi"
                            multiline
                            fullWidth={true}
                        />
                    </Paper>
                </Grid>*/}
                <Grid item xs={12} sm={12} md={4} />


                <Grid item xs={12} sm={12} md={4}>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <OrderFormAlerts order={order} />

                </Grid>

            </Grid>
            <Grid container spacing={2} padding={1} direction={{xs: 'column', md: 'row'}}>
                <Grid item xs={12} sm={6} md={4}>

                </Grid>

                <Grid item xs={12}>
                    <Box display="flex" justifyContent={'flex-end'} p={2}>

                    </Box>

                </Grid>


            </Grid>
        </Box>
    )

}