import {IProductAddToOrderInfoConstructor} from "../../interfaces/product/IProductAddToOrderInfoConstructor";
import dayjs from "dayjs";
import {ProductCategory} from "./ProductCategory";
import {IMainPhoto} from "../../interfaces/product/IMainPhoto";
import {MainProductPhoto} from "./MainProductPhoto";
import categoryService from "../../services/CategoryService";
import UnitLocale from "../unit/UnitLocale";
import {NullableString} from "../../interfaces/NullableStringType";

export class ProductAddToOrderInfo {
    productId: number;
    name?: string;
    basicCode: string;
    codeSuffix?: string;
    producerCode?: string;
    producerNameAndCode?: string;
    supplierNameAndCode?: string;
    fullCode: string;
    mainPhotoId?: number;
    productErpUpdatedAt: string;
    productErpCreatedAt: string;
    category: ProductCategory;
    sellPrice: number;
    sellVat: number;
    sellPriceVat: number;
    mainPhoto?: IMainPhoto;
    categoryTree: string[];
    basicUnitId: number;
    unitLocale: UnitLocale;
    defaultStoreCode: NullableString;


    constructor(j:IProductAddToOrderInfoConstructor) {
       this.productId = j.productId;
       this.name = j.name;
       this.basicCode = j.basicCode;
       this.codeSuffix = j.codeSuffix;
       this.producerCode = j.producerCode;
       this.producerNameAndCode = j.producerNameAndCode;
       this.supplierNameAndCode = j.supplierNameAndCode;
       this.fullCode = j.fullCode;
       this.mainPhotoId = j.mainPhotoId;
       this.productErpUpdatedAt = dayjs(j.productErpUpdatedAt).format('DD.MM.YYYY HH:mm');
       this.productErpCreatedAt = dayjs(j.productErpCreatedAt).format('DD.MM.YYYY HH:mm');
       this.category = j.category;
       this.sellPrice = j.sellPrice;
       this.sellVat = j.sellVat;
       this.sellPriceVat = j.sellPriceVat;
       this.basicUnitId = j.basicUnitId;
       this.defaultStoreCode = j.defaultStoreCode;
       this.categoryTree = categoryService.returnCategoryTree(this.category.stack);
       this.unitLocale = new UnitLocale(j.unitLocale);
       if(j.mainPhoto !== null){
           this.mainPhoto = new MainProductPhoto(j.mainPhoto!);
       }

    }

}