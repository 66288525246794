import React, { useEffect } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import adressService from "../../services/AddressService";
import { ICustomerAddressSearch } from "../../interfaces/address/ICustomerAddressSearch";

interface IProps {
    onSelect(item: ICustomerAddressSearch | null): void;
    size?: "small" | "medium";
}

export const AddressFulltextSelect: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const [loaded, setLoaded] = React.useState(true); // `true` by default to avoid showing spinner on initial render
    const [addresses, setAddresses] = React.useState<ICustomerAddressSearch[]>([]);
    const [selected, setSelected] = React.useState<ICustomerAddressSearch | null>(null);
    const [query, setQuery] = React.useState("");

    const fetchAddresses = (searchText: string) => {
        setLoaded(false);
        adressService.search(searchText).then((res) => {
            setAddresses(res);
            setLoaded(true);
        });


    };

    useEffect(() => {
        if (query) {
            fetchAddresses(query);
        } else {
            setAddresses([]);
        }
    }, [query]);

    const onChange = (item: ICustomerAddressSearch | null) => {
        props.onSelect(item);
    };

    return (
        <Autocomplete
            value={selected}
            filterOptions={(options) => options}
            size={props.size ?? "medium"}
            onChange={(event, newValue) => {
                setSelected(newValue);
                onChange(newValue);
            }}
           getOptionLabel={(option) =>
                `[${option.customerName || ""}] -> ${option.addressName || ""} (${option.street || ""}, ${option.city || ""}) `
            }
            inputValue={query}
            onInputChange={(event, newInputValue) => setQuery(newInputValue)}
            options={addresses}
            loading={!loaded}
            noOptionsText={t("No options available")}
            renderInput={(params: any) => <TextField {...params} label={t('orders.form.customerAddress')}/>}
        />
    );
};
