import React, {useEffect} from "react";
import {Box, Button, Snackbar, useTheme} from "@mui/material";

export const MenuAppVersion = () => {
    //const appVersion :string = process.env.REACT_APP_VERSION ?? '0.0.0';
    const appVersionUser = localStorage.getItem('version');
    //const hasUserLatestVersion = appVersion === appVersionUser;
    const theme = useTheme();
    const [isNewVersion, setIsNewVersion] = React.useState(false);
    const [newVersion, setNewVersion] = React.useState('');

    const refreshVersion = () => {
        localStorage.setItem('version', newVersion);
        window.location.href = window.location.href + '?refresh=' + new Date().getTime();
    }

    const fetchMeta = async () => {
        const response = await fetch(`${process.env.PUBLIC_URL}/meta.json`);
        const meta = await response.json();
        if (meta.version !== appVersionUser) {
            console.log("New version: " + meta.version);
            setNewVersion(meta.version);
            setIsNewVersion(true);
        }
    };

    useEffect(() => {
        fetchMeta();
    })

    const Action = (
        <Button variant="contained" color="info" size="small" onClick={refreshVersion}>Aktualizovat</Button>
    );

    return (
        <Box display={'flex'} flexDirection='column' justifyContent="center" marginRight={1}>
            <Box color={'info'} sx={{fontSize: 11, display: 'flex', flexDirection: 'column'}}>
                <Box >Verze:</Box>
                <Box>{appVersionUser}</Box>
            </Box>
            {!isNewVersion ? null :

                <Snackbar
                    sx={{
                        "& .MuiSnackbarContent-root": {
                            backgroundColor: theme.palette.warning.light,
                            color: theme.palette.error.contrastText
                        }
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={true}
                    onClose={() => null}
                    message={("Nemáte aktuální verzi aplikace (" + newVersion + ")!")}
                    action={Action}

                />
            }
        </Box>
    )
}